import mixpanel from "mixpanel-browser";

mixpanel.init("f4f85468ce39fab32c5a6771dc9332ea", { debug: true });

export const mixPanelIdentifyUser = (id) => {
  mixpanel.identify(id);
};

export const mixPanelTrackEvent = (event, event_data) => {
  mixpanel.track(event, event_data);
};
